<template>
<v-app>
  <div id="app">
    <Menu />
    <router-view />
  </div>
  <template>
  <v-footer
    dark
    padless
  >
    <v-card
      class="flex"
      flat
      tile
    >
      <v-card-text class="py-2 white--text text-center">
       &copy; DeepDoc,  {{ new Date().getFullYear() }}. All rights reserved.

       <v-btn
          v-for="icon in icons"
          :key="icon"
          :justify="end"
          dark
          icon
          :href="icon.url"
          target="_blank"
        >
          <v-icon size="24px">
            {{ icon.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-footer>
</template>
</v-app>
</template>

<script>
import Menu from './components/Menu.vue';
export default {
  name: 'App',
  metaInfo: {
    title: 'DeepDoc - AI powered literature search',
  },

  data: () => ({
    icons: {
      linkedin: {
        icon: 'mdi-linkedin',
        url: 'https://www.linkedin.com/company/rctalert'
      },
      twitter: {
        icon: 'mdi-twitter',
        url: 'https://twitter.com/rctalert?s=20'
        },
    }
  }),
  components: {
    Menu,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&display=swap");
</style>

