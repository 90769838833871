import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#151922 ',
          secondary: '#64B7C0',
          accent: '#AEFEFF',
          error: '#FF5252',
          info: '#FEF1E6',
          success: '#E1E9F2',
          warning: '#4F4F4F',
          anchor: '#AEFEFF',  
        },
      },
    },
  });

export default vuetify; 