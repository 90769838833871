<template>
  <div id="nav">
    <v-app-bar dense elevation="8" prominent color="primary">
      <div class="my-auto">
        <v-app-bar-nav-icon
          @click="drawer = true"
          class="d-flex d-md-none secondary"
        >
        </v-app-bar-nav-icon>
      </div>

      <v-app-bar-title class="my-auto text-no-wrap ml-5">
        <v-img
          contain
          :width="200"
          :position="center"
          src="..//assets/images/logo.png"
        >
        </v-img>
      </v-app-bar-title>

      <v-tabs
        dark
        class="d-none d-md-block"
        color="secondary"
        fixed-tabs
        centered
      >
        <v-tabs-slider color="accent"></v-tabs-slider>
        <v-tab to="/">
          <v-text>About</v-text>
        </v-tab>
        <!-- <v-tab to="/products">
          <v-text>Products</v-text>
        </v-tab> -->
        <v-tab to="/academy">
          <v-text>Academy</v-text>
        </v-tab>
        <v-tab to="/team">
          <v-text>Team</v-text>
        </v-tab>
      </v-tabs>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary color="success">
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="text--accent-4">
          <v-list-item>
            <v-list-item-icon class="mr-menu">
              <v-icon>mdi-information-outline</v-icon>
            </v-list-item-icon>
            <router-link to="/" class="anchor"> About </router-link>
          </v-list-item>

          <!-- <v-list-item>
            <v-list-item-icon class="mr-menu">
              <v-icon>mdi-offer</v-icon>
            </v-list-item-icon>
            <router-link to="/products" class="anchor"> Products </router-link>
          </v-list-item> -->

          <v-list-item>
            <v-list-item-icon class="mr-menu">
              <v-icon>mdi-newspaper-variant-outline</v-icon>
            </v-list-item-icon>
            <router-link to="/science" class="anchor"> Science </router-link>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon class="mr-menu">
              <v-icon>mdi-account-group-outline</v-icon>
            </v-list-item-icon>
            <router-link to="/team" class="anchor"> Team </router-link>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
  }),
};
</script>
